import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import ReactDOM, { Container } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Route, Router, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Spinner } from '@vwfs-bronson/bronson-react';

import initI18n from './services/i18n';
import { history } from './services/routing';
import { store } from './services/redux';
import { getStyles } from './services/brand';
import { getBrandName } from './config';
import NavigationBlockedModal from './components/NavigationBlockedModal/NavigationBlockedModal';

const ConnectedApp = React.lazy(() => {
  return import('./App');
});

const PositionRestore = withRouter((props: { location: { pathname: string; hash: string } }) => {
  const [location, setLocation] = React.useState<string>(props.location.pathname);
  if (location !== props.location.pathname) {
    setLocation(props.location.pathname);
    if (props.location.hash && props.location.hash !== '') {
      setTimeout(() => {
        const id = props.location.hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
  return null;
});

const Init: React.FC = () => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    Promise.all([getStyles(getBrandName()), initI18n(getBrandName())]).then(() => {
      setLoaded(true);
    });
  }, []);
  return loaded ? (
    <React.Suspense fallback={<Spinner fullPage />}>
      <Provider store={store}>
        <Router history={history}>
          <Route component={NavigationBlockedModal} />
          <PositionRestore />
          <ConnectedApp />
        </Router>
      </Provider>
    </React.Suspense>
  ) : null;
};

function render(): void {
  const rootElement = document.getElementById('root');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(rootElement!);
  root.render(<Init />);
}
declare let module: { hot: any };

if (module.hot) {
  module.hot.accept(render);
}

render();
