import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    lng: 'it',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });

export default (brandName: string): Promise<any> => {
  return new Promise(res => {
    const namespaces: Array<string> = [
      'app',
      'contactPageModal',
      'error',
      'landingPage',
      'personalDetails',
      'contactDetails',
      'employmentDetails',
      'paymentDetails',
      'completion',
      'uploadPage',
      'link-expired',
      'step1',
      'summaryPage',
      'steps',
      'validation',
      'shoppingCart',
      'eSignPage',
      'dealerESignPage',
      'form',
      'legitimationPage',
      'esignSuccessPage',
      'esignFailedPage',
      'identificationTimeoutPage',
      'legitimationRefusedPage',
      'creditApprovedPage',
      'creditRefusedPage',
      'creditPendingPage',
      'navigationBlockedModal',
      'confirmDataModal',
    ];
    Promise.all(
      namespaces.map(item => {
        return import(`../../resources/base/content/${item}.json`)
          .then(module => {
            i18n.addResourceBundle('it', item, module.default, true, true);
          })
          .then(() => {
            /**
             * Request brand-specific resources and override (using deep) the
             * resources for specific brand. Make sure to chain those to avoid
             * race conditions.
             */
            import(`../../resources/${brandName}/content/${item}.json`)
              .then(module => {
                i18n.addResourceBundle('it', item, module.default, true, true);
              })
              .catch(() => {});
          });
      })
    ).then(res);
  });
};
